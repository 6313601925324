.badge {
  display: inline-block;
  color: $base-font-color;
  background: rgba($primary-color-lighter, .5);
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  font-weight: normal;

  &--flex {
    display: inline-flex;
    align-items: center;
    padding: 0 0.25rem;
    border-radius: 3px;
    position: relative;

    icon.icon {
      width: 16px;
      height: 16px;

      &--info {
        color: $primary-color;
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--pill {
    border-radius: 2rem;
    padding: 0 0.75rem;
  }

  &--alert {
    background-color: rgba($color-warning, .5);
    text-align: center;
  }

  &--light {
    background-color: $lightest-gray;
  }

  &--dark {
    background-color: $medium-dark-gray;
    color: white;
  }

  &--transparent {
    background-color: rgba($primary-color-lighter, .5);
  }

  &--info {
    color: $primary-color;
    background-color: $lightest-blue;
  }

  &--success {
    color: $color-success;
    background-color: rgba($green-lighter, .3);
  }

  &--warning {
    color: $color-warning;
    background-color: rgba($color-warning, .2);
  }

  &--error {
    color: $color-error;
    background-color: rgba($color-error, .3);
  }

  &--small {
    font-size: $base-font-size * 0.8;
    padding: 0.125rem 0.5rem;
  }
}

po-badge {
  .pop-up {
    min-width: 30rem;
    padding: $base-spacing;
  }

  .badge {
    white-space: nowrap;

    &--warning {
      color: #55400f;
      background-color: #fbf5e7;
    }
  }
}

