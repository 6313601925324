$table-border-color: darken($lightest-gray, 7);
$table-divider-success: $lime;

.po-table {
  background: #fff;
  table-layout: auto;
  margin: 0 0 $base-spacing;
  border: 1px solid $base-border-color;

  &--fixed {
    table-layout: fixed;
  }

  &--alert-text {
    color: $color-error;
  }

  &--borderless {
    border: none;

    thead {
      background-color: $base-background-color;
      border-bottom: 1px solid $table-border-color;
    }

    tfoot {
      border-top: 1px solid $table-border-color;
    }

    &-rows {
      th, td {
        padding: 0.25rem 0.5rem;
      }

      tbody > tr {
        border-bottom: none;
      }
    }
  }

  &--sticky {
    border-spacing: 0;
    border-collapse: separate;
    border-right: none;

    thead th {
      border-bottom: 1px solid $base-border-color;
    }

    tbody {
      th, td {
        border-top: 1px solid $base-border-color;
        border-bottom: 1px solid $base-border-color;

      }

      tr:not(:last-child) {
        th, td {
          border-bottom: none;
        }
      }
    }

    tfoot {
      th, td {
        border-top: 1px solid $base-border-color;
      }
    }
  }

  &__sort-control {
    align-items: center;
    background: transparent;
    border: 0;
    color: $dark-gray;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 0 0.5rem;
    width: 100%;

    .icon {
      height: 10px;
      width: 10px;
      min-width: 10px;
      color: $dark-gray;
      margin-left: $base-extra-small-spacing;
    }
  }

  [aria-sort="none"] {
    .po-table__sort-control {
      .icon {
        color: $medium-dark-gray;
      }
    }
  }

  caption {
    color: $base-font-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    text-align: left;
    margin-bottom: $base-small-spacing;
  }

  &--captioned {
    // fixes Safari bug that makes captions jump around after sorting rows
    margin-top: 2rem;
    position: relative;
  }

  thead,
  tfoot {
    color: $base-font-color;

    td,
    th {
      font-weight: $weight-semibold;
    }

    td:last-of-type:not(.sticky-col) {
      border-right: none;
    }

    tr {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  thead {
    th {
      vertical-align: bottom;
      // border-right: 1px solid $table-border-color;

      &.po-table__sortable-header {
        position: relative;
        transition: background-color 0.2s ease-out;
        min-height: 2.5rem;

        &:hover {
          background: darken($lightest-gray, 5);
        }
      }

      &:last-of-type {
        &:not(.nested-col) {
          // border-right: none;
        }
      }
    }
  }

  &:not(.po-table--borderless,.po-table--invisible) {
    thead {
      border-bottom: 2px solid $base-border-color;
    }

    thead, tbody, tfoot {
      // Replaces hacky fix for border styles with nested columns
      th:not(:first-child),
      td:not(:first-child) {
        border-left: 1px solid $table-border-color;
      }
      th[rowspan]:not(:last-child),
      td[rowspan]:not(:last-child) {
        border-right: 1px solid $table-border-color;
      }

      &.row-warning {
        border-left: 5px solid $color-warning;
      }
    }

    tfoot {
      border-top: 2px solid $base-border-color;
    }

    thead, tfoot {
      background-color: $lightest-gray;
    }
  }

  &--nested-rows {
    tbody {
      &:not(:last-child) {
        border-bottom: 1px solid $table-border-color;;
      }

      &:hover {
        background-color: $lightest-gray;
      }
    }
  }

  tbody {
    td,
    th {
      &:first-child {
        border-left: none;
      }
    }

    td:last-of-type:not(.sticky-col) {
      border-right: none;
    }

    tr {
      transition: background-color 0.2s ease-out;

      &:hover {
        background-color: $lightest-gray;

        .sticky-col {
          background-color: $lightest-gray;
        }
      }

      &:focus {
        outline: none;
      }

      &.expandable-row {
        overflow-y: hidden;
        td {
          padding: 0;

          > div > div {
            // td padding needs to be re-added on an additional inner div so it doesn't interfere with height animations
            padding: 0.5rem;
          }
        }
      }
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid $table-border-color;

    &.empty-state {
      border: inherit;
      border-bottom: 1px solid $table-border-color;
    }
  }

  td,
  th {
    border: none;
    padding: $base-extra-small-spacing;

    &.disabled {
      background-color: $lightest-gray;
    }

    &.text-emphasis {
      font-weight: $weight-semibold;
    }

    &.padding-none {
      padding: 0;
    }

    &.sticky-col {
      position: sticky;
      z-index: 2;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
      clip-path: inset(0px 0px 0px -5px);
      border-left: 1px solid $table-border-color;
      border-right: 1px solid $table-border-color;
      transition: background-color 0.2s ease-out;


      &--left {
        left: 0;
      }
      &--right {
        right: 0;
      }
    }

    .header-subtitle {
      font-weight: $weight-regular;
      font-size: 0.9rem;
    }
  }

  td, th, td > div, th > div {
    &.alert-cell {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  th {
    a {
      text-decoration: underline;
      overflow-wrap: break-word;
      word-break: break-all;
    }

    p {
      word-break: break-all;
    }
  }

  td {
    overflow: visible;
    text-overflow: initial;
    white-space: initial;
    position: relative;
    min-height: 2rem;

    &.icon-only {
      .icon {
        margin: 0 auto;
        border-radius: 50%;
        background: white;
      }
    }

    > .dropdown {
      margin: 0;
    }

    &.empty-state {
      border-radius: 0;
    }

    > ul:not(.list--plain) {
      margin: 0;
      padding: 0 0 0 1rem;
    }
  }

  td {
     p:last-of-type {
       margin: 0;
     }
   }

  thead, tfoot {
    th, td {
      &.sticky-col {
        background-color: $lightest-gray;
      }
    }
  }

  tbody {
    th, td {
      &.sticky-col {
        background-color: $base-background-color;
      }
    }
  }

  input,
  input-field {
    margin: 0;
    height: auto;

    .read-only__container > p {
      margin: 0;
    }
  }

  &__cell-icon {
    display: inline-flex;
    vertical-align: middle;
  }

  .cell {
    &--highlighted {
      @include highlight;

      &--positive {
        @include highlight--positive;

        icon[iconid="arrow"] {
          @include highlight__arrow;
          color: darken($color-success, 10%);
          transform: rotate(-90deg);
        }

        input-field {
          margin-left: 1.75rem;
        }

        .amount-difference {
          font-size: 0.875rem;
          color: darken($color-success, 10%);
        }
      }

      &--neutral {
        @include highlight--neutral;

        icon[iconid="arrow"] {
          @include highlight__arrow;
          color: darken($gold, 10%);
          transform: rotate(90deg);
        }

        input-field {
          margin-left: 1.75rem;
        }

        .amount-difference {
          font-size: 0.875rem;
          color: darken($gold, 30%);
        }
      }
    }
  }

  &__table-divider {
    background-color: $lightest-gray;
  }

  &__table-divider--success {
    background-color: $table-divider-success;
  }

  &__table-alert {
    background-color: tint($red, 80%);
  }

  &__table-success {
    background-color: tint($green, 80%);

    &__table-divider:hover {
      background-color: $lightest-gray!important;
    }

    .po-table__cell-validation-icon {
      display: flex;
      position: absolute;
      left: 5px;
      top: 5px;
      background: white;
      border: 1px solid white;
      border-radius: 50%;
      height: 15px;
      width: 15px;
      min-height: 15px;
      min-width: 15px;
    }

    .data--error {
      .icon {
        color: $color-error;
      }
    }

    .data--success {
      .icon {
        color: $color-success;
      }
    }

    .align-center {
      text-align: center;
    }

    .align-right {
      text-align: right;
    }

    &--sticky-header {
      thead {
        th,
        th.po-table__sortable-header {
          background-color: $lightest-gray;
          position: sticky;
          top: -1.7rem;
          z-index: 1;
          height: 2.5rem;

          &:after {
            background-color: $base-border-color;
            bottom: -1px;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }

    .inline-button-group__container {
      box-shadow: none;
    }
  }

  .align-headings {
    &--center {
      th {
        text-align: center;
      }
    }

    &--left {
      th {
        text-align: left;
      }
    }

    &--right {
      th {
        text-align: right;
      }
    }
  }

  .align-data {
    &--center {
      td,
      input {
        text-align: center;
      }
    }

    &--left {
      td,
      input {
        text-align: left;
      }
    }

    &--right {
      td,
      input {
        text-align: right;
      }
    }
  }

  .align-icon {
    &--middle {
      .icon {
        vertical-align: middle;
      }
    }
  }

  th, td {
    &.align-right {
      text-align: right;
      > .flex:not(.flex--justify-content-between,.flex--justify-content-start) {
        justify-content: end;
      }
    }
  }

  th.align-left {
    text-align: left;
  }

  th.align-center {
    text-align: center;
  }

  td.align-left {
    text-align: left;
  }

  td.align-center {
    text-align: center;
  }

  &--invisible {
    border: none;

    tr {
      border: none;
    }

    thead {
      background: none;
      border-bottom: 1px solid $base-border-color;

      th {
        border: none;
      }
    }

    tbody {
      td,
      th {
        border: none;
      }

      tr.border-bottom {
        border-bottom: 1px solid $base-border-color;
      };
    }

    tfoot {
      border-top: 1px solid $base-border-color;

      td {
        border: none;
      }
    }
  }

  &--nested {
    border: 0;
    margin: 0;
    min-height: 6rem;
    table-layout: fixed;

    tbody {
      tr:last-child {
        border-bottom: 0;
      }
    }
  }
}

.collapse-icon {
  transition: transform 0.1s ease-out;
  cursor: pointer;
  $arrow-size: 14px;
    height: $arrow-size;
    width: $arrow-size;
    min-height: $arrow-size;
    min-width: $arrow-size;

  &.expanded {
    transform: rotate(90deg);
  }
}