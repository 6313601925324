table {
  border-collapse: collapse;
  font-feature-settings: $font-features-numbers;
  margin: $base-small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid $base-border-color;
  font-weight: $weight-semibold;
  padding: calc($base-extra-small-spacing / 2) $base-extra-small-spacing;
  text-align: left;

  &.required::after {
    content: "*";
    color: $color-required;
    font-size: $base-font-size;
  }
}

td {
  border-bottom: $base-border;
  padding: $base-extra-small-spacing;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

tbody.borderless {
  tr, td {
    border-bottom: none;
    padding: 0;
  }
}

tr,
td,
th {
  vertical-align: middle;
}

.table-container {
  display: flex;
}

.table-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  .table-body {
    padding: $base-small-spacing 0 0;
  }

  .table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    transition: $base-transition;

    &.highlight {
      &:hover {
        background-color: #eaecee;
      }
    }

    &.table-header {
      min-height: 1rem;
      border-bottom: 1px solid $light-gray;

      &:hover {
        background-color: transparent;
      }

      .icon--more {
        visibility: hidden;
      }
    }
  }

  .table-data {
    position: relative;
    padding: 0.25rem 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    textarea {
      max-height: 3.5rem;
      margin: 0;
    }

    input {
      margin: 0;
    }
  }

  .table-data,
  .table-header .table-data {
    flex-grow: 0;
  }

  .icon {
    text-align: center;
    overflow: visible;
  }
}

.table-label {
  color: $primary-color;
  padding-top: .5rem;
  padding-bottom: .5rem;

  &--centered {
    text-align: center;
  }

  &--blue {
    background: $light-blue;
  }

  &--green {
    background: $lime;
  }

  &--yellow {
    background: $yellow;
  }
}

table,
.table-section {

  @for $i from 2 through 12 {
    .col-#{$i} { width: calc(100 / $i)#{'%'}; }
  }

  @for $i from 1 through 40 {
    .col-#{$i}-rem { width: #{$i}rem; }
    .col-min-#{$i}-rem { min-width: #{$i}rem; }
  }

  .col-max-15-rem { max-width: 15rem; }
}

.table-overflow {
  overflow-x: auto;
  width: 100%;

  &__inside {
    &--30-rem { min-width: 30rem; }
    &--40-rem { min-width: 40rem; }
    &--50-rem { min-width: 50rem; }
    &--60-rem { min-width: 60rem; }
    &--70-rem { min-width: 70rem; }
    &--80-rem { min-width: 80rem; }
  }
}

.table {

  &--default {
    margin-bottom: 2rem;

    caption {
      color: $royal;
      font-size: 1rem;
      margin: 0 .5rem .25rem;
      text-align: left !important;
    }

    tr {
      border-bottom-style: hidden;

      &.last {
        border-bottom-style: inherit;
      }

      &:last-child {
        border-bottom: $base-border;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom-style: inherit;
        }
      }
    }

    .table__footer {
      font-weight: $weight-bold;
    }

    input, input-field {
      margin-bottom: 0;
    }
  }

  &--metrics {

    tr {
      height: 50px;
    }

    .table-data input[type="text"],
    .table-data .dropdown {
      margin-bottom: 0;
    }
  }

  &--allocations {

    tr {
      height: 36px;
    }

    input[type="text"] {
      margin-bottom: 0;
      padding: .125rem .5rem;
    }
  }
}

.data-value {
  font-weight: $weight-bold;
  margin: 1.5rem 0;
  display: flex;
  align-items: center;

  &__text {
    margin-left: .5rem;
  }
}
